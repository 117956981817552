import { AuthType } from '@helpers/AuthClient';
import VerifyEmailView from '@views/VerifyEmailView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const VerifyEmailAppPage: FC<PageProps> = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <VerifyEmailView authType={AuthType.App} />
    </>
  );
};

export default VerifyEmailAppPage;
