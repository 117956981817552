import BigLoader from '@components/BigLoader';
import PageMeta from '@components/PageMeta';
import { useAuth } from '@context/AuthContext';
import { useCreateToast } from '@context/ToastContext';
import { AuthType } from '@helpers/AuthClient';
import getAuthPageUrl from '@helpers/getAuthPageUrl';
import isBrowser from '@helpers/isBrowser';
import useTranslation from '@helpers/useTranslation';
import AuthTemplate from '@templates/AuthTemplate';
import { Link, navigate } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { Content, Heading } from 'react-bulma-components';
import { DASHBOARD_PROFILE_PAGE } from '../../constants';

interface VerifyEmailViewProps {
  authType: AuthType;
}

enum VerificationStatus {
  Idle = 'IDLE',
  Ready = 'READY',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

const VerifyEmailView: FC<VerifyEmailViewProps> = ({ authType }) => {
  const { t } = useTranslation(['auth', 'validation', 'pages']);

  const { authClient } = useAuth();

  const createToast = useCreateToast();

  const searchParams = isBrowser ? new URLSearchParams(window.location.search) : null;
  const code = searchParams?.get('code') ?? null;

  const [verifyStatus, setVerifyStatus] = useState<VerificationStatus>(() =>
    code ? VerificationStatus.Ready : VerificationStatus.Idle
  );

  const [verifyError, setVerifyError] = useState<string>();

  useEffect(() => {
    if (verifyStatus !== VerificationStatus.Ready || !code) {
      return;
    }

    setVerifyStatus(VerificationStatus.Loading);

    authClient
      .verifyAccount(code)
      .then(() => {
        setVerifyStatus(VerificationStatus.Success);

        createToast(t('verifyEmailSuccess'), 'success');

        const redirectPage =
          authType === AuthType.Web
            ? DASHBOARD_PROFILE_PAGE
            : getAuthPageUrl('login', AuthType.App);

        navigate(redirectPage);
      })
      .catch(error => {
        setVerifyError(error.message);

        setVerifyStatus(VerificationStatus.Error);
      });
  }, [authClient, authType, code, createToast, t, verifyStatus]);

  const resendVerifyEmailUrl = getAuthPageUrl('resendVerifyEmail', authType);

  return (
    <AuthTemplate>
      <PageMeta description={t('pages:verifyEmailDescription')} title={t('pages:verifyEmail')} />
      <AuthTemplate.Content>
        {verifyStatus === VerificationStatus.Loading ? (
          <BigLoader />
        ) : verifyStatus === VerificationStatus.Idle || verifyError ? (
          <Content textAlign="center">
            <Heading size={5}>
              {t(verifyError ? 'common:somethingWentWrong' : 'verifyYourEmail')}
            </Heading>
            <p>{verifyError ?? t('verifyInstructions')}</p>
            <Link to={resendVerifyEmailUrl}>{t('resendVerificationEmail')}</Link>
          </Content>
        ) : null}
      </AuthTemplate.Content>
    </AuthTemplate>
  );
};

export default VerifyEmailView;
